// ==========================================================================
// LAYOUT / FOOTER
// ==========================================================================

#page-footer {
	background: $go-blue;
	padding-top: $base-spacing;
	padding-bottom: $base-spacing * 4;
	color: lighten( $go-lightblue, 20% );
	text-align: center;
	font-size: 0.9em;

	a {
		color: lighten( $go-lightblue, 20% );

		&:hover {
			color: #fff;
		}
	}

	@include grid-media($grid-md){
		text-align: left;
		font-size: 1em;

		.copyright,
		nav {
			float: left;
			position: relative;
		}
		.copyright {
			padding-right: 1em;
		}

		nav {
			border-left: 1px solid $go-lightblue;

			a {
				padding-left: 1em;
				padding-right: 1em;
			}
		}
	}
}