// ==========================================================================
// LAYOUT / FORMS
// ==========================================================================
$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7);

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 400;
  margin-bottom: $small-spacing / 2;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

#{$all-text-inputs} {
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $_form-box-shadow;
  box-sizing: border-box;
  margin-bottom: 0;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $_form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}


//
// Form Groups
// --------------------------------------------------------------------------
.form-group {
  margin-bottom: $base-spacing;
  position: relative;

  > label {
    font-size: 1em;
    margin-bottom: 0;
    padding: 3px;
  }

  span {
    label {
      font-size:90%;
    }

    .desc {
      font-size:70%;
      text-transform: uppercase;
      padding-left: $base-spacing / 3;
      padding-top: 4px;
      letter-spacing: 0.04em;
    }
  }

  input {
    padding: $small-spacing;
    font-size: 1.25em;
  }

  &--btn {
    text-align: center;
    padding-top: $small-spacing;

    .btn {
      background: $go-red;
      color: $go-yellow;

      &:hover {
        background: $go-yellow;
        color: $go-blue;
      }
      
      @include grid-media($grid-lg){
        padding: 1.5em 3em;
        font-size: $base-font-size * 1.2;
      }

    }
  }

}



/* Parsley Errors */
.parsley-errors-list {
  // background: lighten( red, 45%);
  // border: 1px solid lighten( red, 42%);
  margin-bottom: 1em;
  padding: 0.5em;
  color: red;

  &.filled li {
    font-size: 0.9em;
    font-weight: 800;
  }

}
.parsley-custom-error-message {
 

}
.parsley-error {
  border: 1px solid red;
  box-shadow: 0 0 10px rgba(red, 0.2);
  margin-bottom: 0;
}



.form-container {

  .legal {
    font-size: 70%;
    
      @include grid-media($grid-md){
        font-size: 75%;
      }
      @include grid-media($grid-lg){
        font-size: 85%;
      }
  }

}


/* Temp to hide other form sections for preview */
#step-2 {
  display: none;
}

