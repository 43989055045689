// ==========================================================================
// COMPONENTS / IMAGES
// ==========================================================================

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}




.gallery-row {
	margin-bottom: 0;
	display: flex;

	.gallery-col {
		@include grid-column(12);
		display: flex;
		flex-wrap: wrap;
	}

	.gallery-col--wide {}


	.gallery-image {
		margin-bottom: $small-spacing;
		display: flex;
    	justify-content: center;
    	align-items: center;
    	overflow: hidden;

		img {
    		min-width: 100%;
		    min-height: 100%;
		    object-fit: cover;
		}
	}

	&.wrapper--bleed {
		display: block;
		width: 100vw;

		.gallery-col {
			display: block;
			width: 100% !important;
			margin-left: 0 !important;
		}
		.gallery-image {
			width: 100%;
		}
	}


	@include grid-media($grid-sm){
		// 2 Up
		&.gallery-row--2up {
			.gallery-col {
				@include grid-column(6);
			}
		}
		// 3 Up
		&.gallery-row--3up {
			.gallery-col {
				@include grid-column(4);
			}
		}
		// 3 Up Variations
		&.gallery-row--3up-largeL,
		&.gallery-row--3up-largeR,
		&.gallery-row--2up-largeL,
		&.gallery-row--2up-largeR {
			.gallery-col {
				@include grid-column(4);
				flex-wrap: wrap;

			}
			.gallery-col--wide {
				@include grid-column(8);
			}
		}
	}
}
