// ==========================================================================
// PAGES / HOME
// ==========================================================================

//
// Masthead
// --------------------------------------------------------------------------

@mixin logo-width($height) { width: ($height * 1.4); }

#masthead {
	padding-bottom: 20px;
	position: relative;
	z-index: 5;
	background-color: $go-blue;
	background-image: url('../img/banner-sm.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

  	.logo {
	    height: 60px;
	    @include logo-width(60px);
	    padding: 10px;
	    background: #fff;
	    border-radius: 0 0 4px 4px;
	    margin-left: auto;
	    margin-right: auto;
	}

  	@include grid-media($grid-md){
  		background-image: url('../img/banner-lg.jpg');
  		.logo {
		    margin-left: 0;
		}
	}

	@include grid-media($grid-lg){
  		.logo {
		    height: 100px;
		    @include logo-width( 100px );
		}
	}

	.lp & {
		background: transparent;
		padding-bottom: 0px;
		margin-bottom: -60px; /* Logo height */

		@include grid-media($grid-lg){
			margin-bottom: -100px; /* Logo height */
		}
	}
}

//
// Banner
// --------------------------------------------------------------------------

#page-banner {
	background-color: $go-blue;
	background-image: url('../img/banner-sm.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	padding-top: 60px * 1.25;
	padding-bottom: $base-spacing;
	z-index: 0;

	h1 {
		font-family: $alt-font-family;
		color: $go-yellow;
		text-align: center;
		margin-bottom: 0;
	}

	.wrapper {
		@include grid-container;
	}

	@include grid-media($grid-sm){
		padding-top: 60px * 1.5;

		h1 {
			font-size: 3em;
		}
	}

	@include grid-media($grid-md){
		padding-top: $base-spacing * 6;
		background-image: url('../img/banner-lg.jpg');

		h1 {
			text-align: left;
			font-size: 3em;
		}
		.banner-text {
			@include grid-column(5);
			margin-left: 0;
		}
	}

	@include grid-media($grid-lg){
		padding-top: $base-spacing * 8;

		h1 {
			font-size: 3.25em;
		}
		.banner-text {
			@include grid-column(6);
			margin-left: 0;
		}
	}

	@include grid-media($grid-xlg){
		h1 {
			font-size: 4em;
		}
	}

}

//
//  Call to Action Bar
// --------------------------------------------------------------------------
.cta-bar {
	background: $go-red;
	position: relative;
	overflow: hidden;

	h3 {
		padding-top: $small-spacing;	
		padding-bottom: $small-spacing * 1.4;
		margin: 0;
		text-transform: uppercase;
		color: rgba( #fff, 0.8);
		font-weight: 700;
		text-align: center;
		letter-spacing: 0.05em;
		font-size: 1em;
		z-index: 5;
		position: relative;
	}

	@include grid-media($grid-md){
		&:after {
			position: absolute;
			display: block;
			content: ''; 
			width: 60%;
			height: 100px;
			background: darken( $go-red, 8% );
			top: 0;
			right: 0;
		}
		
		.wrapper {
			&:before {
				position: absolute;
				content: '';
				display: block;
				width: 10em;
				height: 10em;
				background: $go-red;
				transform: rotate(45deg);
				top: -3.3em;
				left: 20%;
				z-index: 3;
			}

			&:after {
			position: absolute;
				content: '';
				display: block;
				width: 10em;
				height: 10em;
				background: darken( $go-red, 4% );
				transform: rotate(45deg);
				top: -3.3em;
				left: 25%;
				z-index: 2;
			}
		}

		h3 {
			@include grid-column(7);
			text-align: left;
			padding-top: $small-spacing;
			padding-bottom: $small-spacing;
			font-size: 1.25em;
			letter-spacing: 0;
			font-weight: 300;
		}
	}

	@include grid-media($grid-lg){
		h3 {
			font-size: 1.5em;
		}
		.wrapper {
			&:before {
				top: -2.9em;
				left: 20%;
			}

			&:after {
				top: -2.9em;
				left: 25%;
			}
		}

	}


	@include grid-media($grid-xlg){
		&:after {
			width: 50%;
		}
		.wrapper {
			&:before {
				top: -2.9em;
				left: 33%;
			}

			&:after {
				top: -2.9em;
				left: 38%;
			}
		}

		
	}
}


//
// Form
// --------------------------------------------------------------------------
.form-container {
	@include box-shadow( 0, 4px, 20px, 0, rgba(0,0,0,0.2) );
	background: #fff;
	border-radius: 10px;
	z-index: 20;
	position: relative;

	header, form, footer {
		padding-left: $base-spacing;
		padding-right: $base-spacing;
	}
	
	header {
		padding-top: $base-spacing;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		padding-bottom: $small-spacing;
		text-align: center;
	}
	form {
		padding-top: $base-spacing;
	}
	footer {
		padding-bottom: $base-spacing;
	}
	h2 {
		font-family: $alt-font-family;
		color: $go-lightblue;
		margin-bottom: 0;
	}

	@include grid-media($grid-sm){

		header, form, footer {
			padding-left: $base-spacing * 2;
			padding-right: $base-spacing * 2;
		}
	}

	@include grid-media($grid-md){
		header {
			padding-top: $base-spacing * 1.5;
		}
	}
}

.start-prompt {
	width: 10em;
	margin-left: 3em;
	padding-top: 0.25em;

}

//
// Content
// --------------------------------------------------------------------------
.lp #primary-content {
	padding-top: 0;
	padding-bottom: 0;

	@include grid-media($grid-md){

		.wrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-areas: "copy form";
		}

		.primary-copy {
			grid-area: copy;
			padding-right: 4em;
			font-size: $base-font-size * 1.15;
		}
		.primary-form  {
			grid-area: form;
			margin-top: -200px;
		}
		.start-prompt {
			margin-left: -1em;
			padding-top: 1em;
		}
	}

	@include grid-media($grid-lg){
		.wrapper {
			grid-template-columns: 7fr 5fr;
		}
		.primary-copy {
			padding-top: $base-spacing * 2;
			font-size: $base-font-size * 1.25;
		}
		.start-prompt {
			margin-left: -2em;
		}
	}
}

.primary-copy {
	font-size: $base-font-size;
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;
}

.primary-form  {
	position: relative;
	top: -10px;
}


//
// Opportunities
// --------------------------------------------------------------------------
#opportunities {
	background: #EBF5FA;
	padding-top: $base-spacing;
	padding-bottom: $base-spacing * 2;

	header {
		text-align: center;
		padding-top: $base-spacing;
		margin-bottom: $base-spacing;

		p {
			font-size: $base-font-size;
		}
	}
	h2 {
		font-family: $alt-font-family;
		color: $go-blue;
		margin: 0;
		font-size: $base-font-size * 1.5;
	}

	ul {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 10px;
		grid-row-gap: 15px;

		@include grid-media($grid-sm){
			grid-template-columns: 1fr 1fr 1fr;
		}
		@include grid-media($grid-md){
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}
		@include grid-media($grid-xlg){
			grid-column-gap: 20px;
		}
	}
	li {
		margin: 0;
		padding: 0;

		img {
			margin-bottom: $small-spacing;

		}
		span {
			display: block;
			line-height: 1.25em;
			text-align: center;
		}

	}

	@include grid-media($grid-md){
		header {
			text-align: left;
			p {
				font-size: $base-font-size * 1.25;
			}
		}
		h2 {
			font-size: $base-font-size * 2.5;
		}
		
	}
}