// ==========================================================================
// LAYOUT / SITE
// ==========================================================================


//
// Globals
// --------------------------------------------------------------------------

@-webkit-viewport {
width : device-width; }

@-moz-viewport {
width : device-width; }

@-ms-viewport {
width : device-width; }

@-o-viewport {
width : device-width; }

@viewport {
width : device-width; }


html {
	background: $go-blue;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten( $base-font-color, 75% );
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
}

body {
	background-color: transparent;
	font-family : $base-font-family;
	line-height : $base-line-height;
	color : $base-font-color;
	-webkit-font-smoothing: antialiased;
}

::-moz-selection {
	//background-color : lighten( $action-color, 10% );
	background-color : lighten( $go-yellow, 10% );
	color : $go-blue;
	text-shadow : none; }

::selection {
	//background-color : lighten( $action-color, 10% );
	background-color : lighten( $go-yellow, 10% );
	color : $go-blue;
	text-shadow : none; }


//
// Content Wrappers
// --------------------------------------------------------------------------
.wrapper,
.wrapper--full {
	padding-left: 5%;
	padding-right: 5%;
	width: auto;
	position: relative;
	@include grid-container;
}

.wrapper {
	@include grid-media($grid-lg){
		padding-left: 0;
		padding-right: 0;
		width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	@include grid-media($grid-xlg){
		width: 1170px;
	}

	@include grid-media($grid-xxlg){
		width: 1300px;
	}
}

.wrapper--narrow {
	@include grid-media($grid-lg){
		width: 760px;
	}

	@include grid-media($grid-xlg){
		width: 760px;
	}

	@include grid-media($grid-xxlg){
		width: 960px;
	}
}




/* Basic Text container */
#primary-content {
	padding-top: $base-spacing * 2;
	padding-bottom: $base-spacing * 4;
	background: #fff;
	position: relative;
}

#opportunities, #main-form {
	width: 110%;
	@include breakpoint($sm){
		width: 100%;
	}
}

.form-group {
	text-align: center;
}

.form-group > input {
	width: 90%;
	margin: 0 auto;
}
